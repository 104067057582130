import React, { useState, Suspense } from "react";
import { Link, navigate } from "gatsby";
import loadable from "@loadable/component";
import { Container, Row, Col, Form } from "react-bootstrap";
import { filterCustomStyles } from "../components/DropdownSelectSettings/DropdownSelectSettings";
import sortByOptions from "../search_config/sort_options.json";
import Layout from "../components/layout";

const Select = loadable(() => import("react-select"));
const FilterSearch = loadable(() => import("../components/FilterSearch/FilterSearch"));
const SearchResultsHeading = loadable(() => import("../components/SearchResultsHeading/SearchResultsHeading"));
const PropertyCard = loadable(() => import("../components/PropertyCard/PropertyCard"));
const CtaModule = loadable(() => import("../components/CtaModule/CtaModule"));
const SearchResultsPagination = loadable(() => import("../components/SearchResultsPagination/SearchResultsPagination"));
const NewsLetterModule = loadable(() => import("../components/NewsLetterModule/NewsLetterModule"));
const NoResultProperties = loadable(() => import("../components/NoResultsProperties/NoResultsProperties"));
const NoResultPropertiesLet = loadable(() => import("../components/NoResultsProperties/NoResultsPropertiesLettings"));
const GoogleMapReults = React.lazy(() => import("../components/maps/google/results"));
const LeafletMapReults = React.lazy(() => import("../components/maps/leaflet/results"));


const PropertyResults = (props) => {


    const mapService = process.env.GATSBY_MAP_PROVIDER == "leaflet" ? "leaflet" : "google";


    const changesortby = event => {
        //get sortby value
        let sortbyUrl = '';
        if (event?.value) {
            sortbyUrl = 'sortby-' + event.value + '/'
        }
        //get current url
        const urlwithoutsort_filt = (props?.location_path).split("page-");
        const urlwithoutsort = urlwithoutsort_filt[0].split("sortby-");
        // concat url and navigate
        navigate(urlwithoutsort[0] + sortbyUrl)
    }
    const changeincludesold = event => {
        let sortbyUrl = '';
        //get current url
        const urlwithoutsort_filt = (props?.location_path).split("page-");
        const urlwithoutsort = urlwithoutsort_filt[0].split("sortby-");
        if (document.getElementById('include-sold').checked) {
            if (props?.page_url_data.searchtypeVal === "sales") {
                sortbyUrl = 'includes-sold/'
            }
            else {
                sortbyUrl = 'includes-let-agreed/'
            }
            // concat url and navigate
            navigate(urlwithoutsort[0] + sortbyUrl)
        } else {
            if (props?.page_url_data.searchtypeVal === "sales") {
                navigate(urlwithoutsort[0].replace('includes-sold/', ''))
            }
            else {
                navigate(urlwithoutsort[0].replace('includes-let-agreed/', ''))
            }
        }
    }
    //const changelayout = event => {
    //get current url
    const mymapview_url = (props?.location_path).split("map-view/");
    // concat url and navigate
    //navigate()
    //}
    const sortby_options = [
        { value: '', label: 'Most Recent' },
        { value: 'price-desc', label: 'Highest Price' },
        { value: 'price-asc', label: 'Lowest Price' }
    ];
    //props?.page_url_data?.search_type
    let sortby_key;
    if (props?.page_url_data?.sortVal === props.indexname) {
        sortby_key = 0;
    } else if (props?.page_url_data?.sortVal === props.indexname + "_price_desc") {
        sortby_key = 1;
    } else if (props?.page_url_data?.sortVal === props.indexname + "_price_asc") {
        sortby_key = 2;
    }

    // View on map & list
    const [showMap, setShowMap] = useState(true);
    const [showList, setShowList] = useState(false);

    const [showDesc, setShowDesc] = useState(false)
    const mapView = (e) => {
        setShowMap(false);
        setShowList(true);
    }

    const listView = (e) => {
        setShowList(false);
        setShowMap(true);
    }
    // View on map & list

    var itemListElement = [];
    if (props?.total > 0 && props.hits) {
        (props.hits).map((val, key) => {
            let details_path = '/property-for-sale'
            if (val.search_type == "lettings") {
                details_path = '/property-to-rent'
            }
            if (props.page_url_data.propertyTypeVal === "new_developments") {
                details_path = '/new-home-for-sale'
            }
            let propid = ''
            if (val?.strapi_id)
                propid = val.strapi_id
            else if (val?.objectID)
                propid = val.objectID
            itemListElement.push(
                {
                    "@type": "ListItem",
                    "position": key + 1,
                    "url": process.env.GATSBY_SITE_URL + details_path + '/' + val.slug + '/' + (propid) + '/',
                    "name": val.slug.replace(/-/g, " ")
                }
            )
        })
    }


    var ldJson = props.hits ? {
        "@context": "https://schema.org",
        "@type": "SearchResultsPage",
        "publisher": {
            "@type": "Corporation",
            "name": process.env.GATSBY_SITE_NAME + " in " + process.env.GATSBY_DEFAULT_AREA,
            "logo": {
                "@type": "ImageObject",
                "url": process.env.GATSBY_SITE_URL + `/images/logo.png`,
                "width": 250,
                "height": 100
            }
        },
        "mainEntity": {
            "@type": "ItemList",
            "numberOfItems": props.total,
            "name": props?.pageh1,
            "description": props?.introcopy,
            "itemListElement": itemListElement
        }
    } : '';



    return (
        <Layout
            layout={"sticky-module"}
        >
            <div className="layout-padding-top"></div>

            <div className='layout-grey-bg-theme'>
                <FilterSearch mymapview_url={mymapview_url[0]} page_url_data={props.page_url_data} createResultsUrl={props.createResultsUrl} />

                <div className="search-results-wrapper">
                    <Container>
                        <Row>
                            <Col xl={7}>
                                <SearchResultsHeading introcopy={props.introcopy} total={props?.total} pageh1={props.pageh1} />
                            </Col>
                            {props.total !== 0 &&
                            <Col xl={5} className="d-flex justify-content-md-end align-items-start">
                                <ul className="list-inline search-sort-list d-flex align-items-center">
                                    {
                                        process.env.GATSBY_PROPERTY_INCLUDE_SOLD === "true" ?
                                            <li className="list-inline-item">
                                                <Form.Check
                                                    className="search-results-check"
                                                    inline
                                                    label={props?.page_url_data.searchtypeVal === "sales" ? "Include Sold" : "Let Agreed"}
                                                    name=""
                                                    checked={props?.page_url_data?.soldVal ? true : false}
                                                    type={"checkbox"}
                                                    id={`include-sold`}
                                                    onChange={changeincludesold}
                                                />

                                            </li>
                                            : ""
                                    }

                                    {
                                        process.env.GATSBY_PROPERTY_INCLUDE_SOLD === "true" ? <li className="list-inline-item">
                                            <div className="search-divider"></div>
                                        </li>
                                            : ""
                                    }

                                    <li className="list-inline-item">
                                        <div className="dropdown-select d-flex align-items-center search-results-sort">
                                            <Select
                                                options={sortby_options}
                                                defaultValue={sortby_options[sortby_key]}
                                                value={sortby_options.value}
                                                isSearchable={false}
                                                placeholder={"Most Recent"}
                                                onChange={changesortby}
                                                className={"select-control"}
                                                classNamePrefix={"react-select"}
                                                styles={filterCustomStyles}
                                                components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                            />
                                        </div>
                                    </li>

                                    <li className="list-inline-item">
                                        <div className="search-divider"></div>
                                    </li>

                                    <li className="list-inline-item">
                                        {props?.page_url_data?.layoutVal == "" &&
                                            <Link to={mymapview_url[0] + "map-view/"} className="search-map"><i className="icon icon-map"></i> <span>Map</span></Link>
                                        }
                                        {props?.page_url_data?.layoutVal &&
                                            <Link to={mymapview_url[0]} className="search-map"><i className="icon icon-map"></i><span>Grid</span></Link>
                                        }
                                    </li>
                                </ul>
                            </Col>}
                        </Row>
                        {props.total == 0 &&
                            <Row>
                                <Col>
                                    <div className="no-results-section">
                                        <p>Unfortunately, we do not currently have any properties that match your search criteria.</p><p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
                                        {props.page_url_data?.searchtypeVal == "sales" ? <NoResultProperties /> : props.page_url_data?.searchtypeVal == "lettings" ? <NoResultPropertiesLet /> : ''}
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Container>
                    {props?.page_url_data?.layoutVal == "" &&
                        <Container>
                            {props?.total > 0 &&
                                <div className="property-card-wrapper-main">
                                    {(props.hits).map(function (hit, i) {
                                        let v = i + 1
                                        return (
                                            <>
                                                <PropertyCard myindexval={i} status={hit.status} data={hit} key={i} />
                                                {v > 0 && (v % 6) == 0 && v != props.hitsPerPage &&
                                                    <CtaModule tag="property-results" />
                                                }
                                            </>
                                        )
                                    })
                                    }

                                    {(props.total > props.hitsPerPage) ? <SearchResultsPagination setMypageoption={props.setMypageoption} location_path={props?.location_path} page_url_data={props.page_url_data} total={props?.total} current_page_number={props?.current_page_number} hitsPerPage={props?.hitsPerPage} nbPages={props?.number_of_pages} /> : <div className="empty-space-search"></div>}
                                </div>
                            }
                        </Container>
                    }
                    {props?.page_url_data?.layoutVal &&
                        <div className="map-results">
                            {props?.total > 0 && mapService == "google" &&
                                <GoogleMapReults hits={props?.hits} propertyTypeVal={props?.page_url_data?.propertyTypeVal} />
                            }
                            {props?.total > 0 && mapService == "leaflet" &&
                                <LeafletMapReults hits={props?.hits} propertyTypeVal={props?.page_url_data?.propertyTypeVal} />
                            }
                        </div>
                    }
                </div>
            </div>

            <NewsLetterModule />
        </Layout>
    )
}

export default PropertyResults